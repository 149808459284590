<template>
  <div style="height: 100%, width: 100%">
    <b-modal v-model="modalShow" size="md" :title="$t('template.apply_template_title')" footer-class="footerClass" 
      no-close-on-backdrop  content-class="apply-template-modal shadow"
      @hidden="hidden">

      <b-alert variant="danger" dismissible v-model="errorShow">
        <font-awesome-icon :icon="['fas', 'triangle-exclamation']"/>&nbsp;&nbsp;{{ $t('error.attention_required') }} 
      </b-alert>

      <div class="container pl-0">
        <b-row>
          <b-col cols="12" class="pr-0">
            <b-form-group>
            <b-form-checkbox v-model="enableGroup">
              {{ $t('template.apply_template_enable_group') }}
            </b-form-checkbox>
            </b-form-group>
          </b-col>
          <b-col cols="12" class="pr-0">
            <b-form-group :label="$t('template.apply_template_count')" label-for="template_count">
              <b-input-group>
                <b-input-group-prepend>
                  <b-button id="APPLY_TEMPLATE_SUBTRACT" @click.prevent="countAddMinus(-1)">
                    <font-awesome-icon :icon="['far', 'minus']"/>
                    <b-popover
                      target="APPLY_TEMPLATE_SUBTRACT"
                      placement="top"
                      triggers="hover"
                      :content="$t('template.apply_template_minus')">
                    </b-popover>
                  </b-button>
                </b-input-group-prepend>
                <b-form-input id="template_count" v-model="count" @blur="countFormat" type="text"></b-form-input>
                <b-input-group-append>
                  <b-button id="APPLY_TEMPLATE_ADD" @click.prevent="countAddMinus(1)">
                    <font-awesome-icon :icon="['far', 'plus']"/>
                    <b-popover
                      target="APPLY_TEMPLATE_ADD"
                      placement="top"
                      triggers="hover"
                      :content="$t('template.apply_template_plus')">
                    </b-popover>
                  </b-button>
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </b-col>
          <b-col cols="12" class="pr-0">
            <b-form-group :label="$t('template.apply_template_action')">
              <b-form-radio 
                v-model="action" 
                name="some-radios" 
                :value="ACTION_CHOICE.APPEND">
                  {{ $t('template.apply_template_action_append') }}
              </b-form-radio>
              <b-form-radio 
                v-model="action" 
                name="some-radios"
                :value="ACTION_CHOICE.OVERRIDE">
                  {{ $t('template.apply_template_action_replace') }}
                </b-form-radio>
            </b-form-group>
          </b-col>
        </b-row>
      </div>
      
      <template v-slot:modal-footer="{}">
        
        <b-button size="sm" variant="success" @click="ok">{{ $i18n.t('button.ok') }}</b-button>
        <b-button size="sm" variant="danger" @click="cancel">{{ $i18n.t('button.cancel') }}</b-button>
      </template>
    </b-modal>

    
  </div>
</template>

<script>

const ACTION_CHOICE = {
  APPEND: 'append'
  , OVERRIDE: 'override'
}
Object.freeze(ACTION_CHOICE);


export default {
  name: 'ApplyTemplateConfigModal',
  components: {
  },
  props: {
    show: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      modalShow: false
      , errorShow: false
      , count: 1
      , action: ACTION_CHOICE.APPEND
      , enableGroup: false
    }
  },
  created() {
    this.ACTION_CHOICE = ACTION_CHOICE;
    this.modalShow = this.show;
    if (!this.show) {
      this.$validator.pause();
    }
  },
  beforeDestroy() {
    this.ACTION_CHOICE = null;
  },
  watch: {
    show(newValue) {
      if (newValue) {
        this.$validator.resume();
        this.count = 1;
        this.action = ACTION_CHOICE.APPEND;
        this.enableGroup = false;
      }
      this.modalShow = newValue;
    }
  },
  computed: {

  },
  methods: {
    ok() {
      this.$validator.validate().then(valid => {
        if (valid) {
          this.$emit('ok', { count: this.count, action: this.action, enableGroup: this.enableGroup });
          this.$emit('update:show', false);
        } else {
          this.errorShow = true;
        }
      });
    },
    cancel() {
      this.$emit('cancel');
      this.$emit('update:show', false);
    },
    hidden() {
      this.$validator.pause();
      this.$emit('update:show', false);
    }
    , countAddMinus(delta) {
      if(isNaN(this.count)) {
        this.count = 0;
      }
      const value = parseInt(this.count);
      let newValue = value + delta;
      this.count = newValue < 0? 0 : newValue;
    },
    countFormat() {
      if(typeof this.count != 'number') {
        this.count = Number(this.count);
        if (isNaN(this.count)) {
          this.count = 0;
        }
      }
      const value = parseInt(this.count);
      if(value < 0) {
        this.count = 0;
      } else {
        this.count = value;
      }
    },
  }

}
</script>