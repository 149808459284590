var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "b-modal",
        {
          attrs: {
            size: "lg",
            title: _vm.title,
            "footer-class": "footerClass",
            "content-class": "shadow",
            "no-close-on-backdrop": "",
            scrollable: "",
          },
          on: {
            hidden: function ($event) {
              return _vm.$emit("update:show", false)
            },
          },
          scopedSlots: _vm._u([
            {
              key: "modal-footer",
              fn: function ({ cancel }) {
                return [
                  [
                    _c(
                      "b-button",
                      {
                        attrs: {
                          size: "sm",
                          variant: "success",
                          disabled:
                            _vm.settingsData.hideNonWorking &&
                            (!_vm.start_hour || !_vm.end_hour),
                        },
                        on: { click: _vm.ok },
                      },
                      [_vm._v(_vm._s(_vm.$t("button.ok")))]
                    ),
                  ],
                  _c(
                    "b-button",
                    {
                      attrs: { size: "sm", variant: "danger" },
                      on: {
                        click: function ($event) {
                          return cancel()
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("button.cancel")))]
                  ),
                ]
              },
            },
          ]),
          model: {
            value: _vm.modalShow,
            callback: function ($$v) {
              _vm.modalShow = $$v
            },
            expression: "modalShow",
          },
        },
        [
          _c(
            "b-form-group",
            { attrs: { label: _vm.$t("staff.metrics") } },
            [
              _c(
                "b-form-radio-group",
                {
                  model: {
                    value: _vm.usage,
                    callback: function ($$v) {
                      _vm.usage = $$v
                    },
                    expression: "usage",
                  },
                },
                [
                  _c(
                    "b-form-radio",
                    {
                      staticClass: "setting-checkbox",
                      attrs: { size: "lg", name: "usage", value: "required" },
                    },
                    [_vm._v(_vm._s(_vm.$t(`${_vm.plannerType}.required`)))]
                  ),
                  _c(
                    "b-form-radio",
                    {
                      staticClass: "setting-checkbox",
                      attrs: { size: "lg", name: "usage", value: "available" },
                    },
                    [_vm._v(_vm._s(_vm.$t(`${_vm.plannerType}.available`)))]
                  ),
                ],
                1
              ),
              _c(
                "b-form-radio-group",
                {
                  model: {
                    value: _vm.metrics,
                    callback: function ($$v) {
                      _vm.metrics = $$v
                    },
                    expression: "metrics",
                  },
                },
                [
                  _vm.plannerType === "resource"
                    ? _c(
                        "b-form-radio",
                        {
                          staticClass: "setting-checkbox",
                          attrs: {
                            size: "lg",
                            name: "metrics",
                            value: "staff",
                          },
                        },
                        [
                          _vm._v(
                            _vm._s(_vm.$t(`${_vm.plannerType}.staff_metrics`))
                          ),
                        ]
                      )
                    : _vm._e(),
                  _vm.plannerType === "resource"
                    ? _c(
                        "b-form-radio",
                        {
                          staticClass: "setting-checkbox",
                          attrs: {
                            size: "lg",
                            name: "metrics",
                            value: "event",
                          },
                        },
                        [
                          _vm._v(
                            _vm._s(_vm.$t(`${_vm.plannerType}.event_metrics`))
                          ),
                        ]
                      )
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "b-form-checkbox",
                {
                  staticClass: "d-inline-flex setting-checkbox",
                  attrs: {
                    disabled:
                      _vm.metrics === "staff" && _vm.plannerType === "resource",
                    size: "lg",
                  },
                  model: {
                    value: _vm.settingsData.usageBookings,
                    callback: function ($$v) {
                      _vm.$set(_vm.settingsData, "usageBookings", $$v)
                    },
                    expression: "settingsData.usageBookings",
                  },
                },
                [_vm._v(" " + _vm._s(_vm.$t("booking.title")) + " ")]
              ),
              _c(
                "b-form-checkbox",
                {
                  staticClass: "d-inline-flex ml-3 setting-checkbox",
                  attrs: {
                    disabled:
                      _vm.metrics === "staff" && _vm.plannerType === "resource",
                    size: "lg",
                  },
                  model: {
                    value: _vm.settingsData.usageActivities,
                    callback: function ($$v) {
                      _vm.$set(_vm.settingsData, "usageActivities", $$v)
                    },
                    expression: "settingsData.usageActivities",
                  },
                },
                [_vm._v(" " + _vm._s(_vm.$t("activity.title")) + " ")]
              ),
              _c(
                "b-form-checkbox",
                {
                  staticClass: "d-inline-flex ml-3 setting-checkbox",
                  attrs: {
                    disabled:
                      _vm.metrics === "staff" && _vm.plannerType === "resource",
                    size: "lg",
                  },
                  model: {
                    value: _vm.settingsData.usageTasks,
                    callback: function ($$v) {
                      _vm.$set(_vm.settingsData, "usageTasks", $$v)
                    },
                    expression: "settingsData.usageTasks",
                  },
                },
                [_vm._v(" " + _vm._s(_vm.$t("task.title")) + " ")]
              ),
            ],
            1
          ),
          _vm.plannerType === "staff"
            ? _c(
                "b-form-group",
                { attrs: { label: _vm.$t("staff.contracts") } },
                [
                  _c(
                    "b-form-checkbox",
                    {
                      staticClass: "setting-checkbox",
                      attrs: { size: "lg" },
                      model: {
                        value: _vm.settingsData.singleContracts,
                        callback: function ($$v) {
                          _vm.$set(_vm.settingsData, "singleContracts", $$v)
                        },
                        expression: "settingsData.singleContracts",
                      },
                    },
                    [
                      _vm._v(
                        " " + _vm._s(_vm.$t("staff.single_contracts")) + " "
                      ),
                    ]
                  ),
                ],
                1
              )
            : _vm._e(),
          _c(
            "b-form-group",
            { attrs: { label: _vm.$t("staff.grouping") } },
            [
              _c(
                "b-form-checkbox",
                {
                  staticClass: "setting-checkbox",
                  attrs: { size: "lg" },
                  model: {
                    value: _vm.settingsData.combineLike,
                    callback: function ($$v) {
                      _vm.$set(_vm.settingsData, "combineLike", $$v)
                    },
                    expression: "settingsData.combineLike",
                  },
                },
                [_vm._v(" " + _vm._s(_vm.$t("staff.combine_like")) + " ")]
              ),
            ],
            1
          ),
          _c(
            "b-form-group",
            { attrs: { label: _vm.$t("staff.filtering") } },
            [
              _c(
                "b-form-checkbox",
                {
                  staticClass: "setting-checkbox",
                  attrs: { size: "lg" },
                  model: {
                    value: _vm.settingsData.displayFilteredMetrics,
                    callback: function ($$v) {
                      _vm.$set(_vm.settingsData, "displayFilteredMetrics", $$v)
                    },
                    expression: "settingsData.displayFilteredMetrics",
                  },
                },
                [_vm._v(" " + _vm._s(_vm.$t("staff.display_filtered")) + " ")]
              ),
            ],
            1
          ),
          _c(
            "b-form-group",
            { attrs: { label: _vm.$t("staff.planning_alerts") } },
            [
              _c(
                "b-form-checkbox",
                {
                  staticClass: "setting-checkbox",
                  attrs: { size: "lg" },
                  model: {
                    value: _vm.settingsData.alertBookings,
                    callback: function ($$v) {
                      _vm.$set(_vm.settingsData, "alertBookings", $$v)
                    },
                    expression: "settingsData.alertBookings",
                  },
                },
                [_vm._v(" " + _vm._s(_vm.$t("staff.alert.bookings")) + " ")]
              ),
              _c(
                "b-form-checkbox",
                {
                  staticClass: "setting-checkbox",
                  attrs: { size: "lg" },
                  model: {
                    value: _vm.settingsData.alertActivities,
                    callback: function ($$v) {
                      _vm.$set(_vm.settingsData, "alertActivities", $$v)
                    },
                    expression: "settingsData.alertActivities",
                  },
                },
                [_vm._v(" " + _vm._s(_vm.$t("staff.alert.activities")) + " ")]
              ),
              _c(
                "b-form-checkbox",
                {
                  staticClass: "setting-checkbox",
                  attrs: { size: "lg" },
                  model: {
                    value: _vm.settingsData.alertTasks,
                    callback: function ($$v) {
                      _vm.$set(_vm.settingsData, "alertTasks", $$v)
                    },
                    expression: "settingsData.alertTasks",
                  },
                },
                [_vm._v(" " + _vm._s(_vm.$t("staff.alert.tasks")) + " ")]
              ),
              _vm.showNonWork
                ? _c(
                    "b-form-checkbox",
                    {
                      staticClass: "setting-checkbox",
                      attrs: { size: "lg" },
                      model: {
                        value: _vm.settingsData.alertNonWork,
                        callback: function ($$v) {
                          _vm.$set(_vm.settingsData, "alertNonWork", $$v)
                        },
                        expression: "settingsData.alertNonWork",
                      },
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("staff.alert.nonwork")) + " ")]
                  )
                : _vm._e(),
              _c(
                "b-form-checkbox",
                {
                  staticClass: "setting-checkbox",
                  attrs: { size: "lg" },
                  model: {
                    value: _vm.settingsData.alertTaskBookings,
                    callback: function ($$v) {
                      _vm.$set(_vm.settingsData, "alertTaskBookings", $$v)
                    },
                    expression: "settingsData.alertTaskBookings",
                  },
                },
                [_vm._v(" " + _vm._s(_vm.$t("staff.alert.task_booking")) + " ")]
              ),
              _c(
                "b-form-checkbox",
                {
                  staticClass: "setting-checkbox",
                  attrs: { size: "lg" },
                  model: {
                    value: _vm.settingsData.showOnlyWarnings,
                    callback: function ($$v) {
                      _vm.$set(_vm.settingsData, "showOnlyWarnings", $$v)
                    },
                    expression: "settingsData.showOnlyWarnings",
                  },
                },
                [
                  _vm._v(
                    " " + _vm._s(_vm.$t("staff.alert.only_warnings")) + " "
                  ),
                ]
              ),
            ],
            1
          ),
          _c(
            "b-form-group",
            { attrs: { label: _vm.$t("staff.timescale") } },
            [
              _c(
                "b-form-checkbox",
                {
                  staticClass: "setting-checkbox",
                  attrs: { size: "lg" },
                  model: {
                    value: _vm.settingsData.weekNumbers,
                    callback: function ($$v) {
                      _vm.$set(_vm.settingsData, "weekNumbers", $$v)
                    },
                    expression: "settingsData.weekNumbers",
                  },
                },
                [_vm._v(" " + _vm._s(_vm.$t("staff.week_numbers")) + " ")]
              ),
            ],
            1
          ),
          _c(
            "b-form-group",
            { attrs: { label: _vm.$t("staff.color_styling") } },
            [
              _c(
                "b-form-radio-group",
                {
                  model: {
                    value: _vm.styling,
                    callback: function ($$v) {
                      _vm.styling = $$v
                    },
                    expression: "styling",
                  },
                },
                [
                  _c(
                    "b-form-radio",
                    {
                      staticClass: "setting-checkbox",
                      attrs: { size: "lg", name: "styling", value: "swatch" },
                    },
                    [_vm._v(_vm._s(_vm.$t("staff.color_swatch")))]
                  ),
                  _c(
                    "b-form-radio",
                    {
                      staticClass: "setting-checkbox",
                      attrs: { size: "lg", name: "styling", value: "full" },
                    },
                    [_vm._v(_vm._s(_vm.$t("staff.full_bar_color")))]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "b-form-checkbox",
            {
              staticClass: "setting-checkbox",
              attrs: { size: "lg" },
              model: {
                value: _vm.settingsData.hideNonWorking,
                callback: function ($$v) {
                  _vm.$set(_vm.settingsData, "hideNonWorking", $$v)
                },
                expression: "settingsData.hideNonWorking",
              },
            },
            [_vm._v(" " + _vm._s(_vm.$t("staff.hide_non_working")) + " ")]
          ),
          _c(
            "b-row",
            [
              _c(
                "b-col",
                [
                  _c("label", [_vm._v(_vm._s(_vm.$t("staff.start_hour")))]),
                  _c("Timepicker", {
                    attrs: {
                      name: "start_hour",
                      disableMinutes: true,
                      disableTime: !_vm.settingsData.hideNonWorking,
                    },
                    model: {
                      value: _vm.start_hour,
                      callback: function ($$v) {
                        _vm.start_hour = $$v
                      },
                      expression: "start_hour",
                    },
                  }),
                ],
                1
              ),
              _c(
                "b-col",
                [
                  _c("label", [_vm._v(_vm._s(_vm.$t("staff.end_hour")))]),
                  _c("Timepicker", {
                    attrs: {
                      name: "end_hour",
                      disableMinutes: true,
                      disableTime: !_vm.settingsData.hideNonWorking,
                    },
                    model: {
                      value: _vm.end_hour,
                      callback: function ($$v) {
                        _vm.end_hour = $$v
                      },
                      expression: "end_hour",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "b-form-invalid-feedback",
            {
              staticClass: "alert-danger form-field-alert",
              class: { "d-block": _vm.showTimeError },
            },
            [
              _c("font-awesome-icon", {
                attrs: { icon: ["far", "circle-exclamation"] },
              }),
              _vm._v("  " + _vm._s(_vm.errors.first("working_hours")) + " "),
            ],
            1
          ),
          _c(
            "b-form-checkbox",
            {
              staticClass: "setting-checkbox",
              attrs: { size: "lg" },
              model: {
                value: _vm.settingsData.hideWeekends,
                callback: function ($$v) {
                  _vm.$set(_vm.settingsData, "hideWeekends", $$v)
                },
                expression: "settingsData.hideWeekends",
              },
            },
            [_vm._v(" " + _vm._s(_vm.$t("staff.hide_weekends")) + " ")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }