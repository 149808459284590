var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { height: "100%, width: 100%" } },
    [
      _c(
        "b-modal",
        {
          attrs: {
            size: "md",
            title: _vm.$t("template.apply_template_title"),
            "footer-class": "footerClass",
            "no-close-on-backdrop": "",
            "content-class": "apply-template-modal shadow",
          },
          on: { hidden: _vm.hidden },
          scopedSlots: _vm._u([
            {
              key: "modal-footer",
              fn: function (ref) {
                return [
                  _c(
                    "b-button",
                    {
                      attrs: { size: "sm", variant: "success" },
                      on: { click: _vm.ok },
                    },
                    [_vm._v(_vm._s(_vm.$i18n.t("button.ok")))]
                  ),
                  _c(
                    "b-button",
                    {
                      attrs: { size: "sm", variant: "danger" },
                      on: { click: _vm.cancel },
                    },
                    [_vm._v(_vm._s(_vm.$i18n.t("button.cancel")))]
                  ),
                ]
              },
            },
          ]),
          model: {
            value: _vm.modalShow,
            callback: function ($$v) {
              _vm.modalShow = $$v
            },
            expression: "modalShow",
          },
        },
        [
          _c(
            "b-alert",
            {
              attrs: { variant: "danger", dismissible: "" },
              model: {
                value: _vm.errorShow,
                callback: function ($$v) {
                  _vm.errorShow = $$v
                },
                expression: "errorShow",
              },
            },
            [
              _c("font-awesome-icon", {
                attrs: { icon: ["fas", "triangle-exclamation"] },
              }),
              _vm._v("  " + _vm._s(_vm.$t("error.attention_required")) + " "),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "container pl-0" },
            [
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    { staticClass: "pr-0", attrs: { cols: "12" } },
                    [
                      _c(
                        "b-form-group",
                        [
                          _c(
                            "b-form-checkbox",
                            {
                              model: {
                                value: _vm.enableGroup,
                                callback: function ($$v) {
                                  _vm.enableGroup = $$v
                                },
                                expression: "enableGroup",
                              },
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "template.apply_template_enable_group"
                                    )
                                  ) +
                                  " "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { staticClass: "pr-0", attrs: { cols: "12" } },
                    [
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            label: _vm.$t("template.apply_template_count"),
                            "label-for": "template_count",
                          },
                        },
                        [
                          _c(
                            "b-input-group",
                            [
                              _c(
                                "b-input-group-prepend",
                                [
                                  _c(
                                    "b-button",
                                    {
                                      attrs: { id: "APPLY_TEMPLATE_SUBTRACT" },
                                      on: {
                                        click: function ($event) {
                                          $event.preventDefault()
                                          return _vm.countAddMinus(-1)
                                        },
                                      },
                                    },
                                    [
                                      _c("font-awesome-icon", {
                                        attrs: { icon: ["far", "minus"] },
                                      }),
                                      _c("b-popover", {
                                        attrs: {
                                          target: "APPLY_TEMPLATE_SUBTRACT",
                                          placement: "top",
                                          triggers: "hover",
                                          content: _vm.$t(
                                            "template.apply_template_minus"
                                          ),
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c("b-form-input", {
                                attrs: { id: "template_count", type: "text" },
                                on: { blur: _vm.countFormat },
                                model: {
                                  value: _vm.count,
                                  callback: function ($$v) {
                                    _vm.count = $$v
                                  },
                                  expression: "count",
                                },
                              }),
                              _c(
                                "b-input-group-append",
                                [
                                  _c(
                                    "b-button",
                                    {
                                      attrs: { id: "APPLY_TEMPLATE_ADD" },
                                      on: {
                                        click: function ($event) {
                                          $event.preventDefault()
                                          return _vm.countAddMinus(1)
                                        },
                                      },
                                    },
                                    [
                                      _c("font-awesome-icon", {
                                        attrs: { icon: ["far", "plus"] },
                                      }),
                                      _c("b-popover", {
                                        attrs: {
                                          target: "APPLY_TEMPLATE_ADD",
                                          placement: "top",
                                          triggers: "hover",
                                          content: _vm.$t(
                                            "template.apply_template_plus"
                                          ),
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { staticClass: "pr-0", attrs: { cols: "12" } },
                    [
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            label: _vm.$t("template.apply_template_action"),
                          },
                        },
                        [
                          _c(
                            "b-form-radio",
                            {
                              attrs: {
                                name: "some-radios",
                                value: _vm.ACTION_CHOICE.APPEND,
                              },
                              model: {
                                value: _vm.action,
                                callback: function ($$v) {
                                  _vm.action = $$v
                                },
                                expression: "action",
                              },
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "template.apply_template_action_append"
                                    )
                                  ) +
                                  " "
                              ),
                            ]
                          ),
                          _c(
                            "b-form-radio",
                            {
                              attrs: {
                                name: "some-radios",
                                value: _vm.ACTION_CHOICE.OVERRIDE,
                              },
                              model: {
                                value: _vm.action,
                                callback: function ($$v) {
                                  _vm.action = $$v
                                },
                                expression: "action",
                              },
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "template.apply_template_action_replace"
                                    )
                                  ) +
                                  " "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }