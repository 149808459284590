<template>
  <div>
    <b-modal v-model="modalShow" size="lg" :title="title" footer-class="footerClass"
      @hidden="$emit('update:show', false)"
      content-class="shadow"
      no-close-on-backdrop
      scrollable
    >
      <b-form-group :label="$t('staff.metrics')">
        <b-form-radio-group v-model="usage">
          <b-form-radio class="setting-checkbox" size="lg" name="usage" value="required">{{ $t(`${plannerType}.required`) }}</b-form-radio>
          <b-form-radio class="setting-checkbox" size="lg" name="usage" value="available">{{ $t(`${plannerType}.available`) }}</b-form-radio>
        </b-form-radio-group>
        
        <b-form-radio-group v-model="metrics">
          <b-form-radio v-if="plannerType === 'resource'" class="setting-checkbox" size="lg" name="metrics" value="staff">{{ $t(`${plannerType}.staff_metrics`) }}</b-form-radio>
          <b-form-radio v-if="plannerType === 'resource'" class="setting-checkbox" size="lg" name="metrics" value="event">{{ $t(`${plannerType}.event_metrics`) }}</b-form-radio>
        </b-form-radio-group>
        
        <b-form-checkbox :disabled="metrics === 'staff' && plannerType === 'resource'" class="d-inline-flex setting-checkbox" size="lg" v-model="settingsData.usageBookings">
          {{ $t('booking.title') }}
        </b-form-checkbox>
        
        <b-form-checkbox :disabled="metrics === 'staff' && plannerType === 'resource'" class="d-inline-flex ml-3 setting-checkbox" size="lg" v-model="settingsData.usageActivities">
          {{ $t('activity.title') }}
        </b-form-checkbox>
        
        <b-form-checkbox :disabled="metrics === 'staff' && plannerType === 'resource'" class="d-inline-flex ml-3 setting-checkbox" size="lg" v-model="settingsData.usageTasks">
          {{ $t('task.title') }}
        </b-form-checkbox>
      </b-form-group>
      
      
      <b-form-group v-if="plannerType === 'staff'" :label="$t('staff.contracts')">   
        <b-form-checkbox class="setting-checkbox" size="lg" v-model="settingsData.singleContracts">
          {{ $t('staff.single_contracts') }}
        </b-form-checkbox>
      </b-form-group>
      
      <b-form-group :label="$t('staff.grouping')">   
        <b-form-checkbox class="setting-checkbox" size="lg" v-model="settingsData.combineLike">
          {{ $t('staff.combine_like') }}
        </b-form-checkbox>
      </b-form-group>
      
      <b-form-group :label="$t('staff.filtering')">   
        <b-form-checkbox class="setting-checkbox" size="lg" v-model="settingsData.displayFilteredMetrics">
          {{ $t('staff.display_filtered') }}
        </b-form-checkbox>
        
      </b-form-group>
  
      <b-form-group :label="$t('staff.planning_alerts')">
        <b-form-checkbox class="setting-checkbox" size="lg" v-model="settingsData.alertBookings">
          {{ $t('staff.alert.bookings') }}
        </b-form-checkbox>
        
        <b-form-checkbox class="setting-checkbox" size="lg" v-model="settingsData.alertActivities">
          {{ $t('staff.alert.activities') }}
        </b-form-checkbox>
        
        <b-form-checkbox class="setting-checkbox" size="lg" v-model="settingsData.alertTasks">
          {{ $t('staff.alert.tasks') }}
        </b-form-checkbox>
        
        <b-form-checkbox v-if="showNonWork" class="setting-checkbox" size="lg" v-model="settingsData.alertNonWork">
          {{ $t('staff.alert.nonwork') }}
        </b-form-checkbox>
        
        <b-form-checkbox class="setting-checkbox" size="lg" v-model="settingsData.alertTaskBookings">
          {{ $t('staff.alert.task_booking') }}
        </b-form-checkbox>
        
        <b-form-checkbox class="setting-checkbox" size="lg" v-model="settingsData.showOnlyWarnings">
          {{ $t('staff.alert.only_warnings') }}
        </b-form-checkbox>
      </b-form-group>
      
      <b-form-group :label="$t('staff.timescale')">
        <b-form-checkbox class="setting-checkbox" size="lg" v-model="settingsData.weekNumbers">
          {{ $t('staff.week_numbers') }}
        </b-form-checkbox>
      </b-form-group>
  
      <b-form-group :label="$t('staff.color_styling')">
        <b-form-radio-group v-model="styling">
          <b-form-radio class="setting-checkbox" size="lg" name="styling" value="swatch">{{ $t('staff.color_swatch') }}</b-form-radio>
          <b-form-radio class="setting-checkbox" size="lg" name="styling" value="full">{{ $t('staff.full_bar_color') }}</b-form-radio>
        </b-form-radio-group>
      </b-form-group>
  
      <b-form-checkbox class="setting-checkbox" size="lg" v-model="settingsData.hideNonWorking">
        {{ $t('staff.hide_non_working') }}
      </b-form-checkbox>
    
      <b-row>
        <b-col>
          <label>{{ $t('staff.start_hour') }}</label>
          <Timepicker v-model="start_hour" name="start_hour" :disableMinutes="true" :disableTime="!settingsData.hideNonWorking"></Timepicker>
        </b-col>
        <b-col>
          <label>{{ $t('staff.end_hour') }}</label>
          <Timepicker v-model="end_hour" name="end_hour" :disableMinutes="true" :disableTime="!settingsData.hideNonWorking"></Timepicker>
        </b-col>
      </b-row>
      <b-form-invalid-feedback class="alert-danger form-field-alert" :class="{ 'd-block': showTimeError }">
        <font-awesome-icon :icon="['far', 'circle-exclamation']"/>&nbsp;&nbsp;{{ errors.first('working_hours') }}
      </b-form-invalid-feedback>

      <b-form-checkbox class="setting-checkbox" size="lg" v-model="settingsData.hideWeekends">
        {{ $t('staff.hide_weekends') }}
      </b-form-checkbox>
      
      <template v-slot:modal-footer="{ cancel }">
        <!-- Emulate built in modal footer ok and cancel button actions -->
        <template>
          <b-button size="sm" variant="success" @click="ok" :disabled="settingsData.hideNonWorking && (!start_hour || !end_hour)">{{ $t('button.ok') }}</b-button>
        </template>
        <b-button size="sm" variant="danger" @click="cancel()">{{ $t('button.cancel') }}</b-button>
        
      </template>
    </b-modal>
    
  </div>
</template>

<script>

import { cloneDeep } from 'lodash';

export default {
  name: 'PlannerSettingsModal',
  components: {
    Timepicker: () => import('@/components/Calendar/Timepicker.vue')
  },
  props: {
    show:         { type: Boolean, required: true },
    settings:     { type: Object, default: null },
    properties:   { type: Array, default: null },
    title:        { type: String, default: () => { this.$t('staff.planner_settings.title') } },
    showMetrics:  { type: Boolean, default: true },
    showNonWork:  { type: Boolean, default: true },
    plannerType:         { type: String, default: 'staff' }
  },
  data() {
    return {
      id: Math.random().toString(36).substr(2, 9),
      modalShow: false,
      value: null,
      settingsData: {
        alertBookings: false,
        alertOverlap: false,
        required: true,
        available: false,
        weekNumbers: false,
        singleContracts: true,
        combineLike: false
      },
      usage: 'required',
      metrics: 'staff',
      styling: 'swatch',
      start_hour: null,
      end_hour: null
    }
  },
  created() {
    this.options = this.properties;
  },
  watch: {
    show(newValue) {
      if(newValue != this.modalShow) {
        this.modalShow = newValue;
        if (newValue) {
          this.settingsData = cloneDeep(this.settings);
          if (this.settingsData.required) {
            this.usage = 'required';
          }
          else {
            this.usage = 'available';
          }
          
          if (this.settingsData.work_hours) {
            this.start_hour = `${this.settingsData.work_hours.first_hour}:00`;
            this.end_hour = `${this.settingsData.work_hours.last_hour}:00`;
          }
          
          if (this.settingsData.staff_metrics) {
            this.metrics = 'staff';
          }
          else {
            this.metrics = 'event';
          }
          
          if (this.settingsData.fullBarColor) {
            this.styling = 'full';
          }
          else {
            this.styling = 'swatch';
          }
        }
      }
    },
    start_hour(newValue) {
      // remove minutes to round down to the nearest hour
      if (newValue) {
        const matches = /^(\d+):(\d+).*?$/g.exec(newValue);
        if (matches !== null && matches[2] !== '00') {
          this.$set(this, 'start_hour', `${matches[1]}:00`);
        }
      }
    },
    end_hour(newValue) {
      // remove minutes to round down to the nearest hour
      if (newValue) {
        const matches = /^(\d+):(\d+).*?$/g.exec(newValue);
        if (matches !== null && matches[2] !== '00') {
          this.$set(this, 'end_hour', `${matches[1]}:00`);
        }
      }
    }
  },
  computed: {
    showTimeError() {
      return this.errors.items.length !== 0;
    }
  },
  methods: {
    ok() {
      this.errors.clear();
      if (this.usage === 'required') {
        this.settingsData.required = true;
        this.settingsData.available = false;
      }
      else {
        this.settingsData.required = false;
        this.settingsData.available = true;
      }
  
      if (this.start_hour && this.end_hour) {
        if (!this.settingsData.work_hours) {
          this.settingsData.work_hours = {};
        }
        const matchesStart = /^(\d+):(\d+).*?$/g.exec(this.start_hour);
        if (matchesStart !== null) {
          this.settingsData.work_hours.first_hour = parseInt(matchesStart[1]);
        }
        const matchesEnd = /^(\d+):(\d+).*?$/g.exec(this.end_hour);
        if (matchesEnd !== null) {
          this.settingsData.work_hours.last_hour = parseInt(matchesEnd[1]);
        }
      }
      else {
        this.settingsData.work_hours = null;
      }
      
      if (this.settingsData.hideNonWorking && (this.settingsData.work_hours.first_hour >= this.settingsData.work_hours.last_hour)) {
        this.errors.add({ field: 'working_hours', msg: this.$t('staff.error.working_hours' )});
        return;
      }
      
      if (this.metrics === 'staff') {
        this.settingsData.staff_metrics = true;
      }
      else {
        this.settingsData.staff_metrics = false;
      }
      
      if (this.styling === 'swatch') {
        this.settingsData.fullBarColor = false;
      }
      else if (this.styling === 'full') {
        this.settingsData.fullBarColor = true;
      }
      this.$emit('update:show', false);
      this.$emit('success', this.settingsData );
    }
  }
}
</script>
<style lang="scss">
.del-button {
  position: relative;
  left: -0.5rem;
}

.setting-checkbox {
  font-size: 1em !important;
  margin-top: 4px;
  margin-bottom: 4px;
  line-height: 2 !important;
}
</style>
